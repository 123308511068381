import { useTranslation } from "react-i18next";

import ModalMail from "assets/images/recovery-mail.svg";

import { SIGN_IN } from "constants/routes";

import { Link } from "react-router-dom";

const PasswordRecoveryModal = ({ isVisible }) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <div className="modal fade show d-block" id="successModal">
      <div className="modal-dialog modal-dialog-centered" id="modal-dialog">
        <div
          className="modal-content text-center position-absolute"
          id="modal-content"
        >
          <div className="modal-body">
            <img
              className="modal-title mb-4"
              src={ModalMail}
              alt="Email Icon"
            />
            <h2 className="h5 mb-3">
              {t("auth.passwordRecovery.recoverPassword")}
            </h2>
            <div className="text-white">
              <div className="w-75 m-auto">
                {t("auth.passwordRecovery.recoveryEmailSentMessage")}
              </div>
            </div>
            <div className="text-center mt-4">
              <Link
                to={SIGN_IN.INDEX}
                className="btn btn-warning px-4 text-uppercase"
                data-testid="back_to_sign_in_button"
              >
                {t("auth.passwordRecovery.backToSignIn")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecoveryModal;
