import React,{ useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";


import { AuthSetup } from "contextApi/AuthSetupContext";

import PublicRoutes from "./Routes/public";
import PrivateRoutes from "./Routes/private";

function App() {

  useEffect(() => {
    const href = window.location.href;
    const hostname = window.location.hostname;

    const slug = window.location.href.split(".")[0].split("/")[2];

    if (hostname != 'localhost' && slug.toLowerCase() != 'onboarding') {
      const newHref = href.replace(slug, 'onboarding');

      window.location.replace(newHref);
    }
  }, [])
  
  return (
    <Router>
      <AuthSetup>
        <>
          <PublicRoutes />
          <PrivateRoutes />
        </>
      </AuthSetup>
    </Router>
  );
}

export default App;
