import { Routes, Route, Navigate } from "react-router-dom";

import OtpVerify from "components/SignUp/OtpVerify";

import {
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  OTP_VERIFY,
  SIGN_IN,
  SIGN_UP,
  SIGNIN,
} from "constants/routes";

import SignUp from "pages/SignUp/SignUp";
import SignIn from "pages/SignIn/SignIn";
import ResetPassword from "pages/PasswordReset/ResetPassword";
import NewPassword from "pages/PasswordReset/NewPassword";

const PublicRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to={SIGN_UP.INDEX} replace />} />
    <Route path={SIGN_UP.INDEX} element={<SignUp />} />
    <Route path={SIGN_IN.INDEX} element={<SignIn />} />
    <Route path={SIGNIN.INDEX} element={<SignIn />} />
    <Route path={OTP_VERIFY.INDEX} element={<OtpVerify />} />
    <Route path={FORGOT_PASSWORD.INDEX} element={<ResetPassword />} />
    <Route path={NEW_PASSWORD.INDEX} element={<NewPassword />} />
  </Routes>
);

export default PublicRoutes;
