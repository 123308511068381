const COMPANY_INFO = {
  INDEX: "/company_info",
  EDIT: "/company/edit/:id",
};

const FORGOT_PASSWORD = {
  INDEX: "/forgot_password",
};

const NEW_PASSWORD = {
  INDEX: "/new_password",
};

const HOME = {
  INDEX: "/",
};

const SETUP = {
  INDEX: "/setup",
  PREVIEW: "/setup/preview/:id",
  GAME: "/gamesetup",
  PROFILE: "/profilesetup",
};

const SIGN_IN = {
  INDEX: "/sign_in",
};

const SIGNIN = {
  INDEX: "/signin",
};

const SIGN_UP = {
  INDEX: "/sign_up",
};

const OTP_VERIFY = {
  INDEX: "/otp_verify",
};

export {
  COMPANY_INFO,
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  HOME,
  SETUP,
  SIGN_IN,
  SIGN_UP,
  OTP_VERIFY,
  SIGNIN,
};
