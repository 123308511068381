import React from "react";

const ButtonLoading = ({ btnLabel }) => (
  <div className="inviting d-flex justify-content-center align-items-center">
    <div className="dot-flashing me-4"></div>
    {btnLabel}
  </div>
);

export default ButtonLoading;
