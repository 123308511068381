/**
 *
 * NOTE: Please maintain by alphabetical order; it will be easier to search and look through the file.
 * Add the values according to following labels
 *
 * 1. attributes - This will include site wide attributes values eg: (Email, Name, Password, etc);
 * 2. auth - This will include all the labels for the auth with nested objects;
 *         - Include all the translations inside respective object (eg: login, signUp, passwordReset, etc);
 * 3. common - This will include common translation in app eg: (Job, Candidate, Games, etc);
 * 4. label - This will include all the labels for the buttons eg: (Login, Sign Up, etc);
 * 5. message - This will include common messages that needs to be displayed in app eg: (Email verified, etc);
 * 6. modal - This will include all the labels, description, subheadings for the modal eg: (SignUp modal, Launch setup modal, etc);
 *
 *
 **/

const translation = {
  attributes: {
    passwordStrength: "Password Strength",
    minLength: "minLength",
    capital: "capital",
    lowercase: "lowercase",
    specialChar: "specialChar",
    number: "number",
    match: "match",
  },
  auth: {
    signing: "Signing",
    signingup: "Signing Up",
    otp: {
      resend: "Resend a verification code",
      loadingResend: "Sending verification code",
      subHeading: "Please enter the OTP code from your email",
      title: "Enter OTP code here",
      invalidOTPMessage: "The OTP entered is invalid.",
      otpLimitExceededMessage: "Your attempt has exceeded the maximum limits",
      otpCodeExpired: "OTP code expired",
      resendVerificationCodeSuccessful: "Successfully resent verification code",
    },
    signUp: {
      createAccount: "Create new Account",
      createAnAccount: "Create an Account",
      createNewPassword: "Create new password",
      email: "Enter your valid Email Address*",
      enterEmailPassword: "Enter your work email and pasword.",
      workEmail: "Your Work Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      verifyEmail: "Awesome! Let's verify your email",
      verifyEmailInfo:
        "To start using intelliante platform you need to verify your email address.",
      toolTip: {
        pw: "Click here to know what you should avoid while setting up password",
        onBoarding_title:
          "To know what information is needed when onboarding, ",
        password: {
          pwTitle: "You should avoid using:",
          tip1: "A significant portion of your email address",
          tip2: "Passwords from this ",
          tip2_link: " list of common passwords ",
          tip3: "Passwords from ",
          tip3_link: " Have | Been Pwned ",
          tip3_end: " breach database ",
          tip4: "Words from a dictionary or the name of person, character, product, or organization",
          tip5: "A password you use for another account",
        },
        onBoarding: {
          obTitle: "Information",
          tip1: "Tell us about yourself",
          tip2: "Set your company information",
          tip3: "Choose your own brand and customize theme",
          tip4: "Created an integrated report",
        },
      },
      termsOfuse: "Terms Of Use.",
      privacyPolicy: "Privacy Policy",
      policyErrorMsg:
        "You must agree to our policy(s) with the above check box to proceed.",
      enterValidEmail: "Please enter a valid email.",
      passwordMustMatch: "The passwords must match.",
      signUp: "Sign Up",
      signIn: "Sign In",
      signInNow: " Sign in now",
      passwordRecover: "Recover Password?",
      notStrong: "You password strength must be strong",
      BREACHED: {
        CHECKING: "Checking the Security of this password...",
        SAFE: "This password is safe to use and appeared in no known data breaches.",
        DANGER:
          "We have detected your password on Have I Been Pwned Breach database. Please use different password.",
      },
    },
    signIn: {
      accountLocked: "Please be patient. Account will be unlocked in",
      retryLeft_1: "You have ",
      retryLeft_2: " login attempts left out of",
      blankEmail: "Email address is required*",
      blankPassword: "Password is required*",
    },
    passwordRecovery: {
      emailAddress: "Email Address",
      recoverPassword: "Recover Password",
      userMessage: "Don't worry. It happens to all of us.",
      emailRecoveryLink: "Email my recovery link",
      backToSignIn: "Back to sign in",
      sending: "Sending",
      recoveryEmailSentMessage:
        "Hooray! We will send a recovery link to  email address if an account exists. Just click the link when you get it.",
      letsGoToSignIn: "Let's go to sign in",
      newPasswordCongratulationMessage:
        "Congratulations! you have new password",
      passwordCreationMessage:
        "You can start using this newly password from next time. Keep in mind that you should not share this with anyone else",
      createNewPassword: "Create New Password",
      iKnowMyPassword: "I know my password.Go to login.",
    },
    newPassword: {
      successfullyUpdatedPassword:
        "Congratulations! Your password has been successfully updated",
      useNewPasswordMessage:
        "You can start using this newly created password from next time. Keep in mind that you should not share this with anyone else",
      letsGoToSignIn: "Let's go to sign in",
    },
    error: {
      emailError: "Please enter your email",
      duplicateEmail: "User with this email already exists",
      invalidEmail: "Please enter a valid email",
      emailAlreadyTaken: "This email has already been taken",
      passwordError: "Please enter your password",
      confirmPasswordError: "Password and confirm password must match",
      strengthError: "You password strength must be strong",
      emailOrPasswordInvalid: "Email or password invalid",
      emailEmptyMessage: "Email cannot be empty",
      emailInvalidMessage: "Email address is invalid (you@example.com)",
      passwordEmptyMessage: "Password cannot be empty",
      confirmPasswordEmptyMessage: "Confirm password cannot be empty",
    },
    warning: {
      capsLockOn: "Caps lock is on",
    },
  },
  common: {
    clickHere: "Click here",
    continue: "Continue",
  },
  companyInfo: {
    title: "Company Information",
  },
  formValues: {
    companyName: "Company Name*",
    companySlug: "Company Slug*",
    company: {
      company: "Company",
      name: "Name",
      website: "Website",
      about: "About",
      us: "Us",
      slug: "Slug",
      description: "Description",
    },
    enterCompanyName: "Enter Company Name",
    enterCompanyWebsite: "Enter Company Website",
    enterCompanySlug: "Enter Company Slug",
    descriptionError: "Description exceeds the maximum limit (240 characters).",
    invalidSlug:
      "Slug must not contain multiple words, any special character or upper case",
    slugExceedsAllowedCharacters: "Slug should not exceed 25 characters",
    companyWebsitePlaceholder: "eg: https://www.google.com/",
    companySlugPlaceholder: "Company identifier for your custom URL",
  },
  label: {
    launchSetup: "Launch Setup",
    otpVerify: "Verify Now",
    sendVerificationCode: "Send Verification Code",
    verifying: "Verifying",
  },
  message: {
    auth: {
      capsLockOn: "Caps lock is on",
      emailVerified: "Email verified",
    },
    checklist: {
      minLength: "Use 8 or more characters (14+ is better)",
      capital: "Include uppercase letters",
      lower: "Include lowercase letters",
      specialChar: "Include at least one Symbol",
      number: "Include at least one number",
      match: "Password and confirm password must match",
    },
  },
  modal: {
    otpSuccess: {
      createDashboard: "Let's create your dashboard",
    },
  },
};

export default { translation };
