import BaseRequest from "api/BaseApi";

const USERS = "/users";

class SignUpApi {
  static post(data) {
    return BaseRequest.post(USERS, data);
  }
}

export default SignUpApi;
