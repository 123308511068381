import React, { useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import queryString from "query-string";

import NewPasswordApi from "api/AuthApi/NewPasswordApi";

import BottomCircle from "assets/images/bottom-circle.svg";
import Logo from "assets/images/logo1.png";
import TopCircle from "assets/images/top-circle.svg";

import FormField from "components/Onboarding/CompanyInfo/CompanyInformationForm/FormField";
import Checklist from "components/SignUp/Checklist";
import { validatePassword } from "components/SignUp/PasswordValidator/utils";
import NewPasswordModal from "components/Modal/NewPasswordModal";
import CheckBreached from "components/SignUp/PasswordValidator/CheckBreached";

import { RESPONSE_MESSAGES } from "constants/authentication";
import { SIGN_IN } from "constants/routes";

import { isCapsLockOn } from "utils/getCapsLockStatus";
import {
  isConfirmPasswordInputValid,
  isInputValid,
  isPasswordInputValid,
} from "utils/isInputValid";
import { CONSTANTS } from "utils/localStorageManager";

const INITIAL_PASSWORD_STATE = {
  value: "",
  capsMessage: "",
  show: false,
  hasError: false,
};

const NewPassword = () => {
  const { t } = useTranslation();
  const parsed = queryString.parse(location.search);

  const [password, setPassword] = useState(INITIAL_PASSWORD_STATE);
  const [confirmPassword, setConfirmPassword] = useState(
    INITIAL_PASSWORD_STATE
  );
  const [isPasswordPwned, setIsPasswordPwned] = useState(false);
  const [message, setMessage] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [isNewPasswordModalVisible, setIsNewPasswordModalVisible] =
    useState(false);

  const isPasswordValid =
    validatePassword(password.value) &&
    !isPasswordPwned &&
    password.value === confirmPassword.value;

  const onSubmit = async (e) => {
    e.preventDefault();

    const passwordIsValid = isInputValid(password.value);
    const confirmPasswordIsValid = isInputValid(confirmPassword.value);

    setPassword((prevState) => ({ ...prevState, hasError: !passwordIsValid }));
    setConfirmPassword((prevState) => ({
      ...prevState,
      hasError: !confirmPasswordIsValid,
    }));

    const areInputsValid =
      passwordIsValid && confirmPasswordIsValid && isPasswordValid;

    if (!areInputsValid) return;

    let body = {
      user: {
        reset_password_token: parsed.reset_password_token,
        client_id: CONSTANTS.CLIENT_ID,
        password: password.value,
        password_confirmation: confirmPassword.value,
      },
    };

    try {
      const { data } = await NewPasswordApi.updatePassword(body);
      const isPasswordChangeSuccessful =
        data?.message === RESPONSE_MESSAGES.PASSWORD_SUCCESSFULLY_CHANGED;

      if (isPasswordChangeSuccessful) setIsNewPasswordModalVisible(true);
      else setMessage(data.error_message || data.message || "");
    } catch (error) {
      const { data } = error?.response;

      if (data.error) {
        setMessage(data.error);
      }
    }
  };

  const passwordChangeHandler = (e) => {
    setPassword((prevState) => ({
      ...prevState,
      value: e.target.value,
      hasError: !isInputValid(e.target.value),
    }));
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword((prevState) => ({
      ...prevState,
      value: e.target.value,
      hasError: !isInputValid(e.target.value),
    }));
  };

  const handleShowPassword = () => {
    setPassword((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };

  const handleShowConfirmPassword = () => {
    setConfirmPassword((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };

  const handleFocusPassword = () => {
    setConfirmPassword((prevState) => ({
      ...prevState,
      capsMessage: "",
    }));
  };

  const handleFocusConfirmPassword = () => {
    setPassword((prevState) => ({ ...prevState, capsMessage: "" }));
  };

  const handleCapsPassword = (e) => {
    if (isCapsLockOn(e)) {
      setPassword((prevState) => ({
        ...prevState,
        capsMessage: t("auth.warning.capsLockOn"),
      }));

      setConfirmPassword((prevState) => ({
        ...prevState,
        capsMessage: "",
      }));
    } else {
      setPassword((prevState) => ({ ...prevState, capsMessage: "" }));
    }
  };

  const handleCapsConfirmPassword = (e) => {
    if (isCapsLockOn(e)) {
      setConfirmPassword((prevState) => ({
        ...prevState,
        capsMessage: t("auth.warning.capsLockOn"),
      }));

      setPassword((prevState) => ({ ...prevState, capsMessage: "" }));
    } else {
      setConfirmPassword((prevState) => ({
        ...prevState,
        capsMessage: "",
      }));
    }
  };

  const showPasswordChecklist = password.value.length ? (
    <Checklist
      checkAll={checkAll}
      setCheckAll={setCheckAll}
      password={password.value}
      confirmPassword={confirmPassword.value}
    />
  ) : null;

  const passwordIcon = password.show ? "faEye" : "faEyeSlash";
  const passwordType = password.show ? "text" : "password";

  const confirmPasswordIcon = confirmPassword.show ? "faEye" : "faEyeSlash";
  const confirmPasswordType = confirmPassword.show ? "text" : "password";

  const passwordBreachedClass = checkAll ? "" : "d-none";

  return (
    <div className="container-fluid sign-in sign-up vh-100 position-relative">
      <div className="top-circle  position-absolute top-0 start-0">
        <img src={TopCircle} alt="Top Circle" />
      </div>
      <div className="position-absolute bottom-0 end-0">
        <img src={BottomCircle} alt="Bottom Circle" />
      </div>
      <div className="logo">
        <figure className="text-center m-0">
          <img src={Logo} alt="Logo Intelliante" />
        </figure>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5 mb-4 new-password-container">
          <div className="login-form mt-4 row m-0">
            <div className="form-container p-4 right col-12">
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <form className="form row m-0" onSubmit={handleSubmit}>
                    <div className="col-12">
                      <h5 className="mb-3">
                        {t("auth.signUp.createNewPassword")}
                      </h5>
                      <p className="mb-4 text-error error-message fs-5 new-password-message">
                        {message}
                      </p>
                      <div className="col-12 mt-4">
                        <FormField
                          name="email"
                          containerClassName="new-password-input form-floating"
                          inputClassName="form-control title-input"
                          type="email"
                          placeholder={t("auth.newPassword.emailAddress")}
                          aria-label="Email"
                          value={parsed.email}
                          label={t("auth.passwordRecovery.emailAddress")}
                          iconName="faEnvelope"
                        />
                      </div>
                      <div className="col-12 mt-4">
                        <FormField
                          name="password"
                          containerClassName="new-password-input form-floating"
                          inputClassName="form-control title-input"
                          type={passwordType}
                          placeholder={t("auth.signUp.password")}
                          aria-label="Password"
                          value={password.value}
                          onChangeCallBack={passwordChangeHandler}
                          onKeyUp={handleCapsPassword}
                          onFocus={handleFocusPassword}
                          label={t("auth.signUp.password")}
                          iconName={passwordIcon}
                          handleShowPassword={handleShowPassword}
                          validate={isPasswordInputValid}
                          warningText={password.capsMessage}
                        />
                      </div>

                      <div className="col-12 mt-4">
                        <FormField
                          name="confirmPassword"
                          containerClassName="new-password-input form-floating"
                          inputClassName="form-control title-input"
                          type={confirmPasswordType}
                          placeholder={t("auth.signUp.confirmPassword")}
                          aria-label="confirmPassword"
                          value={confirmPassword.value}
                          onChangeCallBack={confirmPasswordChangeHandler}
                          onKeyUp={handleCapsConfirmPassword}
                          onFocus={handleFocusConfirmPassword}
                          label={t("auth.signUp.confirmPassword")}
                          iconName={confirmPasswordIcon}
                          handleShowPassword={handleShowConfirmPassword}
                          validate={isConfirmPasswordInputValid}
                          warningText={confirmPassword.capsMessage}
                        />
                      </div>
                    </div>

                    <div
                      className={`col-12 breached__pw my-2 ${passwordBreachedClass}`}
                    >
                      <CheckBreached
                        password={password.value}
                        setIsPasswordPwned={setIsPasswordPwned}
                      />
                    </div>

                    {showPasswordChecklist}

                    <div className="col-12 mt-4">
                      <button
                        type="submit"
                        className="btn login-btn text-uppercase  w-100"
                        onClick={onSubmit}
                      >
                        {t("auth.passwordRecovery.createNewPassword")}
                      </button>
                    </div>
                    <div className="col-12 mt-3 text-center">
                      <p className="mb-0 create-account new-password-message">
                        <Link to={SIGN_IN.INDEX} className="btn-link ms-1">
                          {t("auth.passwordRecovery.iKnowMyPassword")}
                        </Link>
                      </p>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <NewPasswordModal isVisible={isNewPasswordModalVisible} />
    </div>
  );
};

export default NewPassword;
