import copyRight from "assets/images/copy-right.png";
import logo from "assets/images/logo-white.png";
import shieldExclamation from "assets/images/shield_exclamation.png";

const CheckCircleIcon = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.9173 11.5424V12.5008C22.916 14.747 22.1887 16.9327 20.8437 18.7318C19.4987 20.5309 17.6082 21.8471 15.4541 22.484C13.3001 23.1209 10.9978 23.0444 8.89073 22.2659C6.78365 21.4875 4.98467 20.0488 3.76207 18.1644C2.53947 16.28 1.95877 14.0508 2.10656 11.8094C2.25436 9.56801 3.12274 7.43443 4.58219 5.72687C6.04164 4.01932 8.01397 2.82928 10.205 2.33424C12.3961 1.8392 14.6884 2.06569 16.7402 2.97992"
      stroke="#FAFEFD"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.9167 4.16797L12.5 14.5951L9.375 11.4701"
      stroke="#FAFEFD"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const COPY_RIGHT = {
  SOURCE: copyRight,
};

const LOGO = {
  SOURCE: logo,
};

const SHIELD_EXCLAMATION = {
  SOURCE: shieldExclamation,
};

export { CheckCircleIcon, LOGO, COPY_RIGHT, SHIELD_EXCLAMATION };
