import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ResetPasswordApi from "api/AuthApi/ResetPasswordApi";

import { SIGN_UP } from "constants/routes";

import LoadingState from "components/SignIn/LoadingState";
import PasswordRecoveryModal from "components/Modal/PasswordRecoveryModal";
import Icon from "components/Shared/Icon";
import { validateEmail } from "components/SignUp/PasswordValidator/utils";

import { CONSTANTS } from "utils/localStorageManager";
import { isInputValid } from "utils/isInputValid";

import { Button, Input } from "@intelliante/intelliante-ui";

const INITIAL_EMAIL_STATE = {
  value: "",
  error: {
    isEmpty: false,
    isInvalid: false,
  },
};

const ResetPassword = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState(INITIAL_EMAIL_STATE);
  const [error, setError] = useState("");
  const [showPasswordRecoveryModal, setShowPasswordRecoveryModal] =
    useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailIsEmpty = !isInputValid(email.value);
    const emailIsValid = validateEmail(email.value);

    setEmail((prevState) => ({
      ...prevState,
      error: {
        isEmpty: emailIsEmpty,
        isInvalid: !emailIsValid,
      },
    }));

    if (emailIsEmpty || !emailIsValid) return;

    setIsSending(true);

    try {
      const body = {
        user: {
          email: email.value,
          client_id: CONSTANTS.CLIENT_ID,
          client_secret: CONSTANTS.CLIENT_SECRET,
        },
      };

      await ResetPasswordApi.resetPassword(body);

      setIsSending(false);
    } catch (err) {
      setIsSending(false);
      setError(err?.response?.data?.error);
    }
    setShowPasswordRecoveryModal(true);
  };

  const emailChangeHandler = (e) => {
    setEmail((prevState) => ({
      ...prevState,
      value: e.target.value?.toLowerCase(),
      error: {
        isEmpty: !isInputValid(e.target.value?.toLowerCase()),
        isInvalid: false,
      },
    }));
  };

  const getEmailMessage = () => {
    if (email.error.isEmpty) return t("auth.error.emailEmptyMessage");
    else if (email.error.isInvalid) return t("auth.error.emailInvalidMessage");
    else return "";
  };

  const emailResponseText = error && (
    <p className="mb-4 response-text">{error}</p>
  );

  const emailError =
    email.error.isEmpty || email.error.isInvalid ? "error" : "";

  const emailTextError = (email.error.isEmpty || email.error.isInvalid) && (
    <span className="text-error error-message">{getEmailMessage()}</span>
  );

  return (
    <div className="sign-in sign-up position-relative">
      <div className="top-circle  position-absolute top-0 start-0">
        <Icon name="TopCircle" alt="Top Circle" />
      </div>
      <div className="bottom-circle position-absolute bottom-0 end-0">
        <Icon name="BottomCircle" alt="Bottom Circle" />
      </div>

      <div className="container-fluid h-100">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-md-11 col-lg-9">
            <div className="login-form row justify-content-center align-items-center m-0 position-relative">
              <div className="bg-image left col-md-6 col-sm-12 p-0 position-relative">
                <figure className="m-0 bg">
                  <Icon name="bg_icon" />
                </figure>
                <div className="logo">
                  <div className="text-center mb-0 create-account position-absolute w-100">
                    <figure className="text-center">
                      <h1>
                        <Icon name="LogoIcon" />
                      </h1>
                    </figure>
                    <Link
                      to={SIGN_UP.INDEX}
                      className="btn-link ms-1 px-5 btn-lg rounded-2"
                      data-testid="create_new_account_button"
                    >
                      {t("auth.signUp.createAnAccount")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="p-4 right col-md-6 col-sm-12">
                <div className="d-flex flex-column justify-content-center h-100">
                  <form className="form row m-0" onSubmit={handleSubmit}>
                    <div className="col-12">
                      <h2 className="recover-pw-title mb-3">
                        {t("auth.passwordRecovery.recoverPassword")}
                      </h2>
                      <p className="recover-pw-title-info mb-4">
                        {t("auth.passwordRecovery.userMessage")}
                      </p>
                      <div className="input-field form-floating mb-1">
                        <Input
                          id="signInEmail"
                          inputType="email"
                          name="email"
                          labelText={t("auth.signUp.email")}
                          placeholderText={t("auth.signUp.email")}
                          value={email.value}
                          iconName="faEnvelope"
                          size="md"
                          variant={emailError}
                          onChange={emailChangeHandler}
                          smallText={emailTextError}
                          dataTestID="email_textbox"
                        />
                        {emailResponseText}
                      </div>
                    </div>

                    <div className="col-12 mt-4">
                      <Button
                        buttonText={
                          <LoadingState
                            isLoading={isSending}
                            loadingLabel={t("auth.passwordRecovery.sending")}
                            defaultLabel={t(
                              "auth.passwordRecovery.emailRecoveryLink"
                            )}
                          />
                        }
                        onClick={handleSubmit}
                        textClassName="w-100 fw-bold text-uppercase"
                        variant="warning"
                        dataTestID="email_recovery_link_button"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PasswordRecoveryModal isVisible={showPasswordRecoveryModal} />
    </div>
  );
};

export default ResetPassword;
