import AuthenticatedApi from "api/AuthenticatedApi";

const COMPANY_URL = "/customers/onboardings/company_setup";
const COMPANY = "/company";

class CompanyApi {
  static get() {
    return AuthenticatedApi.get(COMPANY);
  }

  static post(data) {
    return AuthenticatedApi.post(COMPANY_URL, data);
  }
}

export default CompanyApi;
