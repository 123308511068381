import BaseRequest from "api/BaseApi";

const RESET_PASSWORD_URL = "/users/password";

class ResetPasswordApi {
  static resetPassword(data) {
    return BaseRequest.post(RESET_PASSWORD_URL, data);
  }
}

export default ResetPasswordApi;
