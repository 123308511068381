import AuthenticatedApi from "api/AuthenticatedApi";
import BaseRequest from "api/BaseApi";
import { CLIENT_INFO } from "constants/authentication";

const AUTH = "/oauth/token";

const INITIAL_DATAS = {
  grant_type: CLIENT_INFO.grant_type,
  client_id: CLIENT_INFO.client_id,
  client_secret: CLIENT_INFO.client_secret,
  scope: CLIENT_INFO.scope,
};

const URL = {
  TOKEN: "/oauth/token",
};

class SignInApi {
  /**
   *
   * @param {string} email
   * @param {string} password
   * @param {string} grant_type
   * @param {string} client_id
   * @param {string} client_secret
   * @param {string} refresh_token
   * @param {string} access_token
   * @param {string} scope
   * @returns
   */

  static post(data) {
    const { email, password } = data;

    return BaseRequest.post(AUTH, { ...INITIAL_DATAS, email, password });
  }

  static login(data) {
    return BaseRequest.post(URL.TOKEN, {
      ...data,
    });
  }

  static refreshAuth(
    grant_type,
    refresh_token,
    client_id,
    client_secret,
    scope
  ) {
    return AuthenticatedApi.post(URL.TOKEN, {
      grant_type,
      refresh_token,
      client_id,
      client_secret,
      scope,
    });
  }
}

export default SignInApi;
