import React from "react";
import { useTranslation } from "react-i18next";
import PasswordChecklist from "react-password-checklist";

import Icon from "components/Shared/Icon";

import PasswordStrengthMeter from "./PasswordStrength/PasswordStrengthMeter";

const Checklist = ({ password, confirmPassword, checkAll, setCheckAll }) => {
  const { t } = useTranslation();
  const displayClassName = password?.length > 0 ? "d-block" : "d-none";

  const passwordChecklistRules = [
    t("attributes.minLength"),
    t("attributes.capital"),
    t("attributes.lowercase"),
    t("attributes.specialChar"),
    t("attributes.number"),
    t("attributes.match"),
  ];

  const checklistMessages = {
    minLength: t("message.checklist.minLength"),
    capital: t("message.checklist.capital"),
    lowercase: t("message.checklist.lower"),
    specialChar: t("message.checklist.specialChar"),
    number: t("message.checklist.number"),
    match: t("message.checklist.match"),
  };

  return (
    <div className={displayClassName}>
      <div className="col-12 mt-2">
        <PasswordStrengthMeter password={password} checkAll={checkAll} />
      </div>

      <div className="col-12 mt-2">
        <PasswordChecklist
          rules={passwordChecklistRules}
          minLength={8}
          value={password}
          valueAgain={confirmPassword}
          iconSize={10}
          iconComponents={{
            ValidIcon: <Icon name="valid" className="me-2" />,
            InvalidIcon: <Icon name="invalid" className="me-2" />,
          }}
          messages={checklistMessages}
          onChange={(e) => setCheckAll(e)}
        />
      </div>
    </div>
  );
};

export default Checklist;
