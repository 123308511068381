import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Input } from "@intelliante/intelliante-ui";

import { validateEmail } from "components/SignUp/PasswordValidator/utils";

import { FORGOT_PASSWORD } from "constants/routes";

import { isCapsLockOn } from "utils/getCapsLockStatus";
import { isInputValid } from "utils/isInputValid";

import LoadingState from "./LoadingState";

const INITIAL_PASSWORD_STATE = {
  value: "",
  capsMessage: "",
  show: false,
  hasError: false,
};

const SignInForm = ({ onSubmit, error, flag, isLoading, userEmail }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState({
    value: userEmail || "",
    error: {
      isEmpty: false,
      isInvalid: false,
    },
  });
  const [password, setPassword] = useState(INITIAL_PASSWORD_STATE);

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailIsEmpty = !isInputValid(email.value);
    const emailIsValid = validateEmail(email.value);
    const passwordIsValid = isInputValid(password.value);

    setEmail((prevState) => ({
      ...prevState,
      error: {
        isEmpty: emailIsEmpty,
        isInvalid: !emailIsValid,
      },
    }));
    setPassword((prevState) => ({ ...prevState, hasError: !passwordIsValid }));

    if (!emailIsValid || emailIsEmpty || !passwordIsValid) return;

    onSubmit(email.value, password.value);
  };

  const emailChangeHandler = (e) => {
    setEmail((prevState) => ({
      ...prevState,
      value: e.target.value?.toLowerCase(),
      error: {
        isEmpty: !isInputValid(e.target.value?.toLowerCase()),
        isInvalid: false,
      },
    }));
  };

  const passwordChangeHandler = (e) => {
    setPassword((prevState) => ({
      ...prevState,
      value: e.target.value,
      hasError: !isInputValid(e.target.value),
    }));
  };

  const handleCaps = (e) => {
    const capsText = isCapsLockOn(e) ? t("auth.warning.capsLockOn") : "";

    return setPassword((prevState) => ({
      ...prevState,
      capsMessage: capsText,
    }));
  };

  const handleShowPassword = () => {
    setPassword((prevState) => ({ ...prevState, show: !prevState.show }));
  };

  const getInvalidEmailMessage = () => {
    if (email.error.isEmpty) return t("auth.error.emailEmptyMessage");
    if (email.error.isInvalid) return t("auth.error.emailInvalidMessage");

    return "";
  };

  const emailError =
    flag || email.error.isEmpty || email.error.isInvalid ? "error" : "";

  const passwordIcon = password.show ? "faEye" : "faEyeSlash";
  const passwordType = password.show ? "text" : "password";
  const passwordError = flag || password.hasError ? "error" : "";
  const passwordErrorText = password.hasError
    ? t("auth.error.passwordEmptyMessage")
    : "";

  const emailTextError = (email.error.isEmpty || email.error.isInvalid) && (
    <span className="text-error error-message">{getInvalidEmailMessage()}</span>
  );

  const passwordTextWarning = password.capsMessage;

  const passwordTextError = (
    <span className="">
      <span className="text-error error-message">
        {`${passwordErrorText ? passwordErrorText : ""}`}
      </span>
      <span className="text-warning warning-message">
        {`${"  "}${passwordTextWarning ? password.capsMessage : ""}`}
      </span>
    </span>
  );

  const signInTextError = error && (
    <p className="mb-4 response-text">{error}</p>
  );

  return (
    <form className="form w-100 mw-100 row mx-auto" onSubmit={handleSubmit}>
      <div className="col-12">
        <h2 className="h4 mb-4">{t("auth.signUp.signIn")}</h2>
      </div>

      {signInTextError}

      <div className="col-12 email-input mb-4">
        <Input
          id="signInEmail"
          inputType="email"
          name="email"
          labelText={t("auth.signUp.workEmail")}
          value={email.value}
          iconName="faEnvelope"
          size="md"
          variant={emailError}
          onChange={emailChangeHandler}
          smallText={emailTextError}
          activeLabel=""
          dataTestID="sign_in_email_textbox"
        />
      </div>

      <div className="col-12 password-input">
        <Input
          inputType={passwordType}
          name="password"
          value={password.value}
          labelText={t("auth.signUp.password")}
          onChange={passwordChangeHandler}
          onKeyUp={handleCaps}
          iconName={passwordIcon}
          onIconClick={handleShowPassword}
          variant={passwordError}
          size="md"
          smallText={passwordTextError}
          activeLabel=""
          dataTestID="password_textbox"
        />
      </div>

      <div className="col-12 mb-3">
        <Link
          to={FORGOT_PASSWORD.INDEX}
          className="btn-link"
          data-testid="recover_password_button"
        >
          {t("auth.signUp.passwordRecover")}
        </Link>
      </div>

      <div className="col-12 mt-2">
        <Button
          buttonText={
            <LoadingState
              isLoading={isLoading}
              loadingLabel={t("auth.signing")}
              defaultLabel={t("auth.signUp.signIn")}
            />
          }
          onClick={handleSubmit}
          textClassName="w-100 fw-bold text-uppercase"
          variant="warning"
          dataTestID="sign_in_button"
        />
      </div>
    </form>
  );
};

export default SignInForm;
