import { Routes, Route } from "react-router-dom";

import CompanyDetail from "components/Onboarding/CompanyInfo/CompanyDetail";

import { COMPANY_INFO } from "constants/routes";

import ProtectedRoute from "./protected";

const PrivateRoutes = () => (
  <Routes>
    <Route path={COMPANY_INFO.INDEX} element={<ProtectedRoute />}>
      <Route path="" element={<CompanyDetail />} />
    </Route>
  </Routes>
);

export default PrivateRoutes;
