import React, { useContext, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { t } from "i18next";
import { Form } from "react-final-form";

import { Button } from "@intelliante/intelliante-ui";

import CompanyApi from "api/CompanyApi/CompanyApi";

import Loader from "components/Loader/Loader";
import FormField from "components/Onboarding/CompanyInfo/CompanyInformationForm/FormField";

import { NEED_DOMINANT_BASELINE_FIX } from "constants/authentication";
import { STORE_TOKEN } from "constants/index";

import { AuthSetupContext } from "contextApi/AuthSetupContext";

import { setLocal } from "utils/helpers";
import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";
import {
  isInputValid,
  isLengthValid,
  requiredCompanyName,
  requiredCompanySlug,
  isValidSlug,
  maxLength,
} from "utils/isInputValid";

const ResponseText = ({ show, errorMessage }) => {
  if (!show) return null;

  return <span className="text-error error-message">{errorMessage}</span>;
};

const CompanyDetail = ({ submit }) => {
  const { setLoggedIn } = useContext(AuthSetupContext);

  const [error, setError] = useState("");

  const [companyName, setCompanyName] = useState({
    value: "",
    error: false,
  });
  const [companyWebsite, setCompanyWebsite] = useState({
    value: "",
  });
  const [companySlug, setCompanySlug] = useState({
    value: "",
    error: false,
  });
  const [companyDescription, setCompanyDescription] = useState({
    value: "",
    error: false,
  });
  const [loading, setLoading] = useState(false);

  const nameChangeHandler = (e) => {
    setCompanyName((prevState) => ({
      ...prevState,
      value: e.target.value,
      error: !isInputValid(e.target.value),
    }));
  };

  const websiteChangeHandler = (e) => {
    setCompanyWebsite((prevState) => ({
      ...prevState,
      value: e.target.value,
    }));
  };

  const slugChangeHandler = (e) => {
    setCompanySlug((prevState) => ({
      ...prevState,
      value: e.target.value,
      error: !isInputValid(e.target.value),
    }));
  };

  const descriptionChangeHandler = (e) => {
    setCompanyDescription((prevState) => ({
      ...prevState,
      value: e.target.value,
      error: !isLengthValid(e.target.value),
    }));
  };

  const onSubmit = async () => {
    setLoading(true);

    if (!companyName.value.trim().length)
      setCompanyName((prevState) => ({ ...prevState, error: true }));

    if (
      !companySlug.value.trim().length ||
      companySlug.value.trim().length > 25
    )
      setCompanySlug((prevState) => ({ ...prevState, error: true }));

    if (!companyName.value || !companySlug.value) setLoading(false);

    const companyData = {
      company: {
        name: companyName.value,
        slug: companySlug.value,
        url: companyWebsite.value,
        description: companyDescription.value,
      },
    };

    try {
      const res = await CompanyApi.post(companyData);

      if (res?.data?.url) {
        onRegisterSuccess(res);
        setLoggedIn(true);
      }
    } catch ({ response: { data } }) {
      setError(data.message || data.errors);
      setLoggedIn(false);
      setLoading(false);
    }
  };

  const onRegisterSuccess = (res) => {
    setLocal(STORE_TOKEN, res?.data?.access_token);

    LocalStorageManager.set(CONSTANTS.ACCESS_TOKEN, res?.data?.access_token);

    LocalStorageManager.set(CONSTANTS.TOKEN_TYPE, CONSTANTS.BEARER);

    window.location.replace(
      `${res?.data?.url}?access_token=${encodeURIComponent(
        res?.data?.access_token
      )}`
    );
  };

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const needDominantBaselineFix = NEED_DOMINANT_BASELINE_FIX;

  return (
    <div className="container-fluid h-100">
      <div
        className={`row d-flex justify-content-center align-items-center min-vh-100 wrapper ${
          loading ? "blur" : ""
        }`}
      >
        <div className="col-md-11 col-lg-10">
          <div className="d-flex justify-content-between px-0 mb-4">
            <div className="d-flex progress-status">
              <div className="progressbar">
                <CircularProgressbar
                  value={(1 / 4) * 100}
                  text={
                    <tspan
                      dy={needDominantBaselineFix ? +10 : 10}
                      dx={needDominantBaselineFix ? -23 : 0}
                    >
                      {` 1/4`}
                    </tspan>
                  }
                  styles={buildStyles({
                    rotation: 0.25,
                    height: "4em",
                    width: "3em",
                    strokeLinecap: "butt",
                    textSize: "2em",
                    pathTransitionDuration: 0.5,
                    pathColor: `#1db27e`,
                    textColor: "#727272",
                    trailColor: "#727272",
                    backgroundColor: "#727272",
                  })}
                />
              </div>
              <p className="green p-2">{t("companyInfo.title")}</p>
            </div>
          </div>

          <section className="company-details p-sm-5">
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                submit = handleSubmit;

                return (
                  <form noValidate onSubmit={handleSubmit} className="row g-4">
                    <ResponseText show={error} errorMessage={error} />
                    <div className="col-12 col-md-6">
                      <FormField
                        name="company"
                        inputClassName="form-control title-input"
                        containerClassName="form-floating"
                        placeholder={t("formValues.companyName")}
                        value={companyName.value}
                        onChangeCallBack={nameChangeHandler}
                        validate={requiredCompanyName}
                        label={t("formValues.companyName")}
                        input
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <FormField
                        name="website"
                        inputClassName="form-control title-input"
                        containerClassName="form-floating company-website-formfield"
                        placeholder={t("formValues.companyWebsitePlaceholder")}
                        value={companyWebsite.value}
                        onChangeCallBack={websiteChangeHandler}
                        label={`${t("formValues.company.company")} ${t(
                          "formValues.company.website"
                        )}`}
                        input
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <FormField
                        name="slug"
                        inputClassName="form-control title-input"
                        containerClassName="form-floating company-slug-formfield"
                        placeholder={t("formValues.companySlugPlaceholder")}
                        value={companySlug.value}
                        onChangeCallBack={slugChangeHandler}
                        validate={composeValidators(
                          requiredCompanySlug,
                          isValidSlug,
                          maxLength
                        )}
                        label={t("formValues.companySlug")}
                        input
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <FormField
                        name="description"
                        inputClassName="form-control title-input"
                        containerClassName="form-floating"
                        placeholder={`${t("formValues.company.about")} ${t(
                          "formValues.company.company"
                        )}`}
                        value={companyDescription.value}
                        onChangeCallBack={descriptionChangeHandler}
                        validate={isLengthValid}
                        label={`${t("formValues.company.about")} ${t(
                          "formValues.company.company"
                        )}`}
                        textArea
                      />
                    </div>
                  </form>
                );
              }}
            />
          </section>
          <div className="d-flex flex-row-reverse">
            <Button
              buttonText={t("common.continue")}
              onClick={(event) => submit(event)}
              textClassName="mt-4 text-uppercase d-flex align-items-center px-5"
              variant="warning"
            />
          </div>
        </div>
      </div>
      <Loader isLoading={loading} />
    </div>
  );
};

export default CompanyDetail;
