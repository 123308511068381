import React from "react";
import ReactDOM from "react-dom";

import App from "containers/App";

import "./i18n/root";

import "assets/scss/index.scss";

import "bootstrap/dist/js/bootstrap.bundle";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
