import React, { useEffect } from "react";

const KEY_NAME_ESC = "Escape";
const KEY_CODE_ESC = 27;
const KEY_EVENT_TYPE = "keydown";

const Modal = ({
  isVisible,
  className,
  children,
  isInformativeModal = false,
  handleClose = () => {},
}) => {
  const handleEscKeyPress = (event) => {
    if (event.key === KEY_NAME_ESC || event.keyCode === KEY_CODE_ESC) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isVisible && isInformativeModal) {
      window.addEventListener(KEY_EVENT_TYPE, handleEscKeyPress);
    }

    return () => {
      if (isInformativeModal) {
        window.removeEventListener(KEY_EVENT_TYPE, handleEscKeyPress);
      }
    };
  }, [isVisible, handleClose]);
  if (!isVisible) {
    document.querySelector("body").classList.remove("overflow-hidden");

    return null;
  }

  if (isVisible)
    document.querySelector("body").classList.add("overflow-hidden");

  return (
    <div className="overlay vh-100 vw-100 position-fixed m-0 p-0">
      <div
        className={`${className} position-absolute top-50 start-50 translate-middle overflow-hidden card border-0`}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
