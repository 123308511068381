import React, { useContext, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import OtpVerifyApi from "api/AuthApi/OtpVerifyApi";

import OtpModal from "components/Modal/OtpModal";
import Icon from "components/Shared/Icon";
import LoadingState from "components/SignIn/LoadingState";

import { RESPONSE_MESSAGES } from "constants/authentication";
import { CheckCircleIcon } from "constants/images";
import { STORE_TOKEN } from "constants/index";
import { COMPANY_INFO, SIGN_UP } from "constants/routes";

import { AuthSetupContext } from "contextApi/AuthSetupContext";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";
import { getQueryParams } from "utils/queryParams";

import OtpInputs from "./OtpInput";

import { Button } from "@intelliante/intelliante-ui";

const OtpVerify = ({ t }) => {
  const { setLoggedIn } = useContext(AuthSetupContext);
  let key = useLocation();

  const navigate = useNavigate();

  let [email, setEmail] = useState(key?.state?.email);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [otpKey, setOtpKey] = useState(key?.state?.secretKey);
  const [showOTPMessage, setShowOTPMessage] = useState(false);
  const [loadingValue, setLoadingValue] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [OTPVerifiedModal, setOTPVerifiedModal] = useState(false);

  const checkCompanyAccess = async () => {
    try {
      const accessTokenRes = await OtpVerifyApi.getAccessToken();

      if (accessTokenRes?.data.redirect_url) {
        LocalStorageManager.set(
          STORE_TOKEN,
          accessTokenRes?.data.access_token.token
        );
        LocalStorageManager.set(
          CONSTANTS.ACCESS_TOKEN,
          accessTokenRes?.data.access_token.token
        );
        LocalStorageManager.set(CONSTANTS.TOKEN_TYPE, CONSTANTS.BEARER);
        window.location.replace(
          `${
            accessTokenRes?.data.redirect_url
          }?access_token=${encodeURIComponent(
            accessTokenRes?.data.access_token.token
          )}`
        );
      }
    } catch (error) {
      const responseStatus = error?.response.status;

      if (responseStatus === 403) {
        setOTPVerifiedModal(true);
      }
    }
  };

  const handleOtpVerify = async () => {
    if (isVerifying) return;

    setIsVerifying(true);

    const filters = {
      otp: otp,
      otp_token: otpKey,
      client_id: CONSTANTS.CLIENT_ID,
    };

    try {
      const res = await OtpVerifyApi.verifyOtpCode(filters);
      const message = res?.data.message;

      if (message === RESPONSE_MESSAGES.OTP_CODE_VERIFIED) {
        onSignupSuccess(res);
        checkCompanyAccess();
      }
    } catch (error) {
      setOtp("");
      document?.querySelector("#form > div > div:nth-child(1) > input")?.focus();
      setLoggedIn(false);
      setIsVerifying(false);
      const errorMessage = error?.response?.data?.message;

      if (errorMessage === RESPONSE_MESSAGES.OTP_LIMIT_EXCEEDED) {
        setError(RESPONSE_MESSAGES.OTP_LIMIT_EXCEEDED);
      } else if (errorMessage === RESPONSE_MESSAGES.OTP_CODE_EXPIRED) {
        setError(RESPONSE_MESSAGES.OTP_CODE_EXPIRED);
      } else {
        setError(`${t("auth.otp.invalidOTPMessage")}`);
      }
    }
  };

  const onSignupSuccess = (res) => {
    setError("");
    setIsVerifying(false);

    LocalStorageManager.set(STORE_TOKEN, res?.data?.access_token.token);
    LocalStorageManager.set(
      CONSTANTS.REFRESH_TOKEN,
      res?.data?.access_token?.refresh_token
    );
    LocalStorageManager.set(
      CONSTANTS.ACCESS_TOKEN,
      res?.data?.access_token?.token
    );
    LocalStorageManager.set(
      CONSTANTS.EXPIRES_IN,
      res?.data?.access_token?.expires_in
    );
    LocalStorageManager.set(
      CONSTANTS.TOKEN_TYPE,
      res?.data?.access_token?.token_type
    );
    setLoggedIn(true);
  };

  const handleSuccess = () => {
    if (!error) {
      setOTPVerifiedModal(false);
      navigate(COMPANY_INFO.INDEX);
    }
  };

  const resendOtp = async () => {
    setLoadingValue(true);
    let filters = {
      email,
      client_id: CONSTANTS.CLIENT_ID,
    };
    const res = await OtpVerifyApi.post(filters);

    setShowOTPMessage(true);
    setTimeout(() => {
      setShowOTPMessage(false);
    }, 10 * 1000);
    setOtpKey(res?.data?.otp_token);
    setLoadingValue(false);
  };

  useEffect(() => {
    const { email, otp_token: otpToken } = getQueryParams(key.search);
    const emailFormatted = email.split("&")[0];

    if (emailFormatted) setEmail(emailFormatted);
    else navigate(SIGN_UP.INDEX);

    if (otpToken) setOtpKey(otpToken);
  }, []);

  useEffect(() => {
    if (otp?.length === 6) {
      handleOtpVerify();
    }
  }, [otp]);

  const otpMessageText = showOTPMessage ? (
    <div className="d-flex justify-content-center mx-auto mb-4 p-2 otp-message">
      <CheckCircleIcon className="color-icon" />
      <p>{t("auth.otp.resendVerificationCodeSuccessful")}</p>
    </div>
  ) : null;

  const errorText = error ? (
    <p className="error-message mt-3">{error}</p>
  ) : null;

  return (
    <div className="container-fluid sign-in sign-up verify-page position-relative min-vh-100">
      <div className="top-circle position-absolute top-0 start-0">
        <Icon name="TopCircle" className="img-fluid" />
      </div>
      <div className="bottom-circle position-absolute bottom-0 end-0">
        <Icon name="BottomCircle" className="img-fluid" />
      </div>
      <div className="logo">
        <figure className="text-center m-0">
          <Icon name="Logo" />
        </figure>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5">
          <div className="p-4 bg-gray position-relative">
            {otpMessageText}
            <div className="text-center verify position-relative">
              <figure className="mb-4">
                <Icon name="OTP" />
              </figure>
              <strong>{t("auth.otp.title")}</strong>
              <p className="mt-2 para">{t("auth.otp.subHeading")}</p>
              {errorText}
              <div className="form d-flex justify-content-center" id="form">
                <OtpInputs otp={otp} setOtp={setOtp} />
              </div>
              <Button
                buttonText={
                  <LoadingState
                    isLoading={isVerifying}
                    loadingLabel={t("label.verifying")}
                    defaultLabel={t("label.otpVerify")}
                  />
                }
                onClick={handleOtpVerify}
                textClassName="mt-4 send-btn btn text-uppercase"
                variant="warning"
              />
              <div className="mt-4">
                <span className="resend" onClick={resendOtp}>
                  <LoadingState
                    isLoading={loadingValue}
                    loadingLabel={t("auth.otp.loadingResend")}
                    defaultLabel={t("auth.otp.resend")}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OtpModal isVisible={OTPVerifiedModal} onLaunchSetup={handleSuccess} />
    </div>
  );
};

export default withTranslation()(OtpVerify);

// The withTranslation is a HOC from react-i18next library which is used to enhance the OtpVerify component with translation capabilities.
// The enhanced component will be the default export and can be used as a regular React component.
