import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Button, Input } from "@intelliante/intelliante-ui";

import Icon from "components/Shared/Icon";
import LoadingState from "components/SignIn/LoadingState";

import { isCapsLockOn } from "utils/getCapsLockStatus";
import { isInputValid } from "utils/isInputValid";

import CheckboxField from "components/Shared/Checkbox";

import CheckBreached from "./PasswordValidator/CheckBreached";
import { validateEmail, validatePassword } from "./PasswordValidator/utils";
import Checklist from "./Checklist";

const INITIAL_PASSWORD_STATE = {
  value: "",
  capsMessage: "",
  show: false,
  hasError: false,
};

const SignUpForm = ({
  onSubmit,
  error,
  setError,
  isLoading,
  setIsModalOpen,
  setModalText,
  isAgree,
  setIsAgree,
  policyError,
  setPolicyError,
  setIsPrivacyPolicy,
}) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState({
    value: "",
    error: {
      isEmpty: false,
      isInvalid: false,
    },
  });
  const [password, setPassword] = useState(INITIAL_PASSWORD_STATE);
  const [confirmPassword, setConfirmPassword] = useState(
    INITIAL_PASSWORD_STATE
  );
  const [isPasswordPwned, setIsPasswordPwned] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [, setStrength] = useState(0);

  const termsOfuse = (
    <a
      className="text-blue cursor-pointer"
      data-tip="custom show"
      data-event="click focus"
      data-for="Sign Up"
      onClick={() => {
        setModalText("Terms Of Use");
        setIsModalOpen(true);
        setIsPrivacyPolicy(false);
      }}
    >
      {t("auth.signUp.termsOfuse")}
    </a>
  );
  const privacyPolicy = (
    <a
      className="text-blue cursor-pointer"
      data-tip="custom show"
      data-event="click focus"
      data-for="Sign Up"
      onClick={() => {
        setModalText("Privacy Policy");
        setIsModalOpen(true);
        setIsPrivacyPolicy(true);
      }}
    >
      {t("auth.signUp.privacyPolicy")}
    </a>
  );

  const isPasswordValid =
    validatePassword(password.value) &&
    !isPasswordPwned &&
    password.value === confirmPassword.value;

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailIsEmpty = !isInputValid(email.value);
    const emailIsValid = validateEmail(email.value);
    const passwordIsValid = isInputValid(password.value);
    const confirmPasswordIsValid = isInputValid(confirmPassword.value);

    setEmail((prevState) => ({
      ...prevState,
      error: {
        isEmpty: emailIsEmpty,
        isInvalid: !emailIsValid,
      },
    }));
    setPassword((prevState) => ({ ...prevState, hasError: !passwordIsValid }));
    setConfirmPassword((prevState) => ({
      ...prevState,
      hasError: !confirmPasswordIsValid,
    }));

    if (!isAgree) setPolicyError(t("auth.signUp.policyErrorMsg"));

    const areInputsValid =
      passwordIsValid &&
      confirmPasswordIsValid &&
      isPasswordValid &&
      !emailIsEmpty &&
      !error &&
      isAgree &&
      emailIsValid;

    if (!areInputsValid) return;

    onSubmit(email.value, password.value, confirmPassword.value);
  };

  const emailChangeHandler = (e) => {
    setEmail((prevState) => ({
      ...prevState,
      value: e.target.value?.toLowerCase(),
      error: { isEmpty: !isInputValid(e.target.value?.toLowerCase()), isInvalid: false },
    }));

    setError("");
  };

  const passwordChangeHandler = (e) => {
    setPassword((prevState) => ({
      ...prevState,
      value: e.target.value,
      hasError: !isInputValid(e.target.value),
    }));

    setError("");
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword((prevState) => ({
      ...prevState,
      value: e.target.value,
      hasError: !isInputValid(e.target.value),
    }));

    setError("");
  };

  const handleShowPassword = () => {
    setPassword((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };

  const handleShowConfirmPassword = () => {
    setConfirmPassword((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };

  const handleFocusPassword = () => {
    setConfirmPassword((prevState) => ({
      ...prevState,
      capsMessage: "",
    }));
  };

  const handleFocusConfirmPassword = () => {
    setPassword((prevState) => ({ ...prevState, capsMessage: "" }));
  };

  const handleCapsPassword = (e) => {
    if (isCapsLockOn(e)) {
      setPassword((prevState) => ({
        ...prevState,
        capsMessage: t("auth.warning.capsLockOn"),
      }));

      setConfirmPassword((prevState) => ({
        ...prevState,
        capsMessage: "",
      }));
    } else {
      setPassword((prevState) => ({ ...prevState, capsMessage: "" }));
    }
  };

  const handleCapsConfirmPassword = (e) => {
    if (isCapsLockOn(e)) {
      setConfirmPassword((prevState) => ({
        ...prevState,
        capsMessage: t("auth.warning.capsLockOn"),
      }));

      setPassword((prevState) => ({ ...prevState, capsMessage: "" }));
    } else {
      setConfirmPassword((prevState) => ({
        ...prevState,
        capsMessage: "",
      }));
    }
  };

  const showPasswordChecklist = password.value.length ? (
    <Checklist
      setStrength={setStrength}
      checkAll={checkAll}
      setCheckAll={setCheckAll}
      password={password.value}
      confirmPassword={confirmPassword.value}
    />
  ) : null;

  const getInvalidEmailMessage = () => {
    if (email.error.isEmpty) return t("auth.error.emailEmptyMessage");
    if (email.error.isInvalid) return t("auth.error.emailInvalidMessage");

    return "";
  };

  const emailError =
    email.error.isEmpty || email.error.isInvalid ? "error" : "";
  const passwordIcon = password.show ? "faEye" : "faEyeSlash";
  const passwordType = password.show ? "text" : "password";
  const passwordError = password.hasError ? "error" : "";
  const passwordErrorText = password.hasError
    ? t("auth.error.passwordEmptyMessage")
    : "";

  const confirmPasswordIcon = confirmPassword.show ? "faEye" : "faEyeSlash";
  const confirmPasswordType = confirmPassword.show ? "text" : "password";
  const confirmPasswordError = confirmPassword.hasError ? "error" : "";
  const confirmPasswordErrorText = confirmPassword.hasError
    ? t("auth.error.confirmPasswordEmptyMessage")
    : "";

  const emailTextError = (email.error.isEmpty || email.error.isInvalid) && (
    <span className="text-error error-message email-error">
      {getInvalidEmailMessage()}
    </span>
  );

  const passwordTextWarning = password.capsMessage;

  const passwordTextError = (
    <span>
      <span className="text-error error-message">
        {`${passwordErrorText ? passwordErrorText : ""}`}
      </span>
      <span className="text-warning warning-message">
        {`${"  "}${passwordTextWarning ? password.capsMessage : ""}`}
      </span>
    </span>
  );

  const confirmPasswordTextWarning = confirmPassword.capsMessage;

  const confirmPasswordTextError = (
    <span>
      <span className="text-error error-message">
        {`${confirmPasswordErrorText ? confirmPasswordErrorText : ""}`}
      </span>
      <span className="text-warning warning-message">
        {`${"  "}${
          confirmPasswordTextWarning ? confirmPassword.capsMessage : ""
        }`}
      </span>
    </span>
  );

  const errorText = error && (
    <p className="mb-4 text-error response-text">{error}</p>
  );

  const headingClassName = error ? "mb-1" : "mb-4";
  const strong = checkAll ? "" : "d-none";

  return (
    <form className="form w-100 row mw-100 mx-auto" onSubmit={handleSubmit}>
      <div className="col-12">
        <h2 className={`h4 text-white ${headingClassName}`}>
          {t("auth.signUp.createAnAccount")}
        </h2>

        {errorText}
      </div>
      <div className="col-12 email-input">
        <Input
          id="signUpEmail"
          inputType="email"
          name="email"
          labelText={t("auth.signUp.email")}
          value={email.value}
          iconName="faEnvelope"
          size="md"
          variant={emailError}
          onChange={emailChangeHandler}
          smallText={emailTextError}
          activeLabel=""
          dataTestID="sign_up_email_textbox"
        />
      </div>

      <div className="avoid_info d-flex">
        <p className="my-3">{t("auth.signUp.toolTip.pw")}</p>
        <div className="icon mt-3">
          <a
            className="cursor-pointer d-flex question-mark-icon"
            data-tip="custom show"
            data-event="click focus"
            data-for="global"
          >
            <Icon className="icon ms-2" name="questionMark" />
          </a>
          <ReactTooltip
            id="global"
            aria-haspopup="true"
            role="example"
            place="right"
            globalEventOff="click"
          >
            <p className="fw-bold">
              {t("auth.signUp.toolTip.password.pwTitle")}
            </p>
            <ul className="list-unstyled">
              <li className="d-flex">
                <div>
                  <Icon className="icon me-2" name="info" />{" "}
                </div>
                <div>{t("auth.signUp.toolTip.password.tip1")}</div>
              </li>
              <li className="d-flex">
                <div>
                  <Icon className="icon me-2" name="info" />{" "}
                </div>
                <div>
                  {t("auth.signUp.toolTip.password.tip2")}
                  <a href="https://en.wikipedia.org/wiki/Wikipedia:10,000_most_common_passwords">
                    {t("auth.signUp.toolTip.password.tip2_link")}
                  </a>
                </div>
              </li>
              <li className="d-flex">
                <div>
                  <Icon className="icon me-2" name="info" />{" "}
                </div>
                <div>
                  {t("auth.signUp.toolTip.password.tip3")}
                  <a href="https://haveibeenpwned.com/Passwords">
                    {t("auth.signUp.toolTip.password.tip3_link")}
                  </a>
                  {t("auth.signUp.toolTip.password.tip3_end")}
                </div>
              </li>
              <li className="d-flex">
                <div>
                  <Icon className="icon me-2" name="info" />{" "}
                </div>
                <div>{t("auth.signUp.toolTip.password.tip4")}</div>
              </li>
              <li className="d-flex">
                <div>
                  <Icon className="icon me-2" name="info" />{" "}
                </div>
                <div>{t("auth.signUp.toolTip.password.tip5")}</div>
              </li>
            </ul>
          </ReactTooltip>
        </div>
      </div>

      <div className="col-md-6 password-input">
        <Input
          inputType={passwordType}
          name="password"
          value={password.value}
          labelText={t("auth.signUp.password")}
          onChange={passwordChangeHandler}
          onFocus={handleFocusPassword}
          onKeyUp={handleCapsPassword}
          iconName={passwordIcon}
          onIconClick={handleShowPassword}
          variant={passwordError}
          size="md"
          smallText={passwordTextError}
          activeLabel=""
          dataTestID="password_textbox"
        />
      </div>

      <div className="col-md-6 mt-3 mt-md-0 password-input">
        <Input
          inputType={confirmPasswordType}
          name="password"
          value={confirmPassword.value}
          labelText={t("auth.signUp.confirmPassword")}
          onChange={confirmPasswordChangeHandler}
          onFocus={handleFocusConfirmPassword}
          onKeyUp={handleCapsConfirmPassword}
          iconName={confirmPasswordIcon}
          onIconClick={handleShowConfirmPassword}
          variant={confirmPasswordError}
          size="md"
          smallText={confirmPasswordTextError}
          activeLabel=""
          dataTestID="confirm_password_textbox"
        />
      </div>

      <div className={`col-12 breached__pw my-2 ${strong}`}>
        <CheckBreached
          password={password.value}
          setIsPasswordPwned={setIsPasswordPwned}
        />
      </div>

      {showPasswordChecklist}

      <div className="col-12 mt-4">
        <div className="terms_and_policy mb-2">
          <CheckboxField
            type="checkbox"
            name="terms_and_privacy"
            checked={isAgree}
            onChange={(e) => {
              setIsAgree(e.target.checked);
              if (e.target.checked) {
                setPolicyError("");
              }
            }}
            label={[
              "By checking the box, you agree to our ",
              privacyPolicy,
              " and ",
              termsOfuse,
            ]}
          />
          <span className="text-error error-message">{policyError}</span>
        </div>
        <Button
          buttonText={
            <LoadingState
              isLoading={isLoading}
              loadingLabel={t("auth.signingup")}
              defaultLabel={t("auth.signUp.signUp")}
            />
          }
          onClick={handleSubmit}
          textClassName="w-100 fw-bold text-uppercase"
          variant="warning"
          dataTestID="sign_up_button"
        />
      </div>
      <div className="ob_info">
        <p className="my-3 text-white more-info">
          {t("auth.signUp.toolTip.onBoarding_title")}
          <a
            className="text-blue cursor-pointer"
            data-tip="custom show"
            data-event="click focus"
            data-for="onBoarding"
          >
            {t("common.clickHere")}
          </a>
        </p>
        <div className="icon mt-3">
          <ReactTooltip
            id="onBoarding"
            aria-haspopup="true"
            role="example"
            place="right"
            globalEventOff="click"
          >
            <p>{t("auth.signUp.toolTip.onBoarding.obTitle")}</p>
            <ul className="list-unstyled">
              <li>
                <Icon className="icon me-2" name="info" />
                {t("auth.signUp.toolTip.onBoarding.tip1")}
              </li>
              <li>
                <Icon className="icon me-2" name="info" />
                {t("auth.signUp.toolTip.onBoarding.tip2")}
              </li>
              <li>
                <Icon className="icon me-2" name="info" />
                {t("auth.signUp.toolTip.onBoarding.tip3")}
              </li>
              <li>
                <Icon className="icon me-2" name="info" />
                {t("auth.signUp.toolTip.onBoarding.tip4")}
              </li>
            </ul>
          </ReactTooltip>
        </div>
      </div>
    </form>
  );
};

export default SignUpForm;
