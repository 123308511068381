const PRIVACY_POLICY_LINK =
  "https://www.intelliante.com/intelliante-privacy-policy";

const TERMS_OF_SERIVCE_LINK =
  "https://www.intelliante.com/intelliante-terms-of-use-policy";

export const PrivacyPolicy = () => (
  <iframe src={PRIVACY_POLICY_LINK} height={600} />
);

export const TermsOfUse = () => (
  <iframe src={TERMS_OF_SERIVCE_LINK} height={600} />
);
