import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ModalMail from "assets/images/recovery-success.svg";

import Modal from "components/Shared/Modal/Modal";

import { SIGN_IN } from "constants/routes";

const NewPasswordModal = ({ className, isVisible }) => {
  const { t } = useTranslation();
  const modalClassName = className || "";

  return (
    <Modal
      className={`mw-100 mb-4 py-4 px-5 text-center text-white bg-section new-password-modal ${modalClassName}`}
      isVisible={isVisible}
    >
      <div>
        <img className="mb-4" src={ModalMail} alt="Mail" />
        <h5 className="mb-3">
          {t("auth.newPassword.successfullyUpdatedPassword")}
        </h5>
        <p className="mb-4 new-password-message">{t("auth.newPassword.useNewPasswordMessage")}</p>

        <div className="modal-footer justify-content-center">
          <Link to={SIGN_IN.INDEX} className="btn btn-primary text-uppercase">
            {t("auth.newPassword.letsGoToSignIn")}
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default NewPasswordModal;
