import React, { useState, useEffect } from "react";

import Auth from "api/AuthApi/Auth";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

export const AuthSetupContext = React.createContext({});

export const AuthSetup = ({ children }) => {

  const [isLoggedIn, setLoggedIn] = useState(null);

  const [userInfo, setUserInfo] = useState(false);

  const [updateUser, setUpdateUser] = useState(Date.now());

  useEffect(() => {
    LocalStorageManager.set(CONSTANTS.LOGGED_IN, false);
  },[]);

  const validLogin = async () => {
    try {
      const res = await Auth.validLogin();

      LocalStorageManager.set(CONSTANTS.LOGGED_IN, true);

      setUserInfo(res?.data);
      setLoggedIn(true);
    } catch (error) {
      LocalStorageManager.set(CONSTANTS.LOGGED_IN, false);
      setLoggedIn(false);
    }
  };

  const handleLogout = () => {
    LocalStorageManager.clear();

    setLoggedIn(false);
  };

  useEffect(() => {
    if (isLoggedIn) validLogin();
  }, [isLoggedIn, updateUser]);

  return (
    <AuthSetupContext.Provider
      value={{
        isLoggedIn,
        setLoggedIn,
        handleLogout,
        userInfo,
        setUpdateUser,
        fetchUserInfo: validLogin,
      }}
    >
      {children}
    </AuthSetupContext.Provider>
  );
};
