import HIBPPasswordChecker from "react-have-i-been-pwned";
import { useTranslation } from "react-i18next";

const CheckBreached = ({ password, setIsPasswordPwned }) => {
  const { t } = useTranslation();

  return (
    <p>
      <HIBPPasswordChecker password={password}>
        {({ initial, loading, error, pwned }) => {
          if (initial) return null;
          if (!password) return null;
          if (loading) return <>{t("auth.signUp.BREACHED.CHECKING")}</>;
          if (error) return `error: ${error}`;
          if (!pwned) {
            setIsPasswordPwned(false);

            return (
              <span className="text-success">
                {t("auth.signUp.BREACHED.SAFE")}
              </span>
            );
          }
          if (pwned) {
            setIsPasswordPwned(true);

            return <>{t("auth.signUp.BREACHED.DANGER")}</>;
          }
        }}
      </HIBPPasswordChecker>
    </p>
  );
};

export default CheckBreached;
