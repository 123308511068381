import AuthenticatedApi from "api/AuthenticatedApi";

const VALID_TOKEN_PATH = `/me`;

class Auth {
  static async validLogin() {
    return AuthenticatedApi.get(`${VALID_TOKEN_PATH}`);
  }
}

export default Auth;
