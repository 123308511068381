import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

export const DEFAULT_HEADERS = {
  Accept: "application/json",
};

export const FORM_DATA_HEADERS = {
  "Content-Type": "multipart/form-data",
};

class BaseRequest {
  static headers() {
    return { headers: DEFAULT_HEADERS };
  }

  static get(url) {
    return axios.get(url, this.headers());
  }

  static getHeaders(url, header) {
    return axios.get(url, header);
  }

  static post(url, data) {
    return axios.post(url, data, this.headers());
  }

  static postAuth(url, data, headers) {
    return axios.post(url, data, headers);
  }

  static patch(url, data) {
    return axios.patch(url, data, this.headers());
  }

  static putFile(url, data) {
    return axios.put(url, data, this.headers(FORM_DATA_HEADERS));
  }

  static put(url, data) {
    return axios.put(url, data, this.headers());
  }

  static delete(url) {
    return axios.delete(url, this.headers());
  }
}

export default BaseRequest;
