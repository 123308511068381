import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";

import SignInApi from "api/AuthApi/SignInApi";

import Icon from "components/Shared/Icon";
import SignInForm from "components/SignIn/SignInForm";

import { MAX_FAILED_ATTEMPTS } from "constants/authentication";
import { OTP_VERIFY, SIGN_UP, FORGOT_PASSWORD, SIGNIN } from "constants/routes";

import { CONSTANTS } from "utils/localStorageManager";

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const userEmail = location?.state?.state?.email || "";
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isNewSignInPage = location.pathname === SIGNIN.INDEX;

  const navigateToOTPVerification = (email, password, res) =>
    navigate(
      `${OTP_VERIFY.INDEX}?email=${encodeURIComponent(
        email
      )}&otp_token=${encodeURIComponent(res?.data.encrypted_otp_token)}`,
      {
        state: {
          email: email,
          password: password,
        },
      }
    );

  const handleSubmit = async (email, password) => {
    setIsLoading(true);
    const data = {
      email: email,
      password: password,
      grant_type: CONSTANTS.PASSWORD,
      client_id: CONSTANTS.CLIENT_ID,
      client_secret: CONSTANTS.CLIENT_SECRET,
      scope: `${process.env.REACT_APP_CUSTOMER_SCOPE}`,
    };

    try {
      const res = await SignInApi.login(data);

      if (res.data.otp_verification_required) {
        setIsLoading(false);
        navigateToOTPVerification(email, password, res);
      }
    } catch (error) {
      setIsLoading(false);
      const data = error?.response?.data;

      const message = data?.message;
      const failedAttempts = data?.total_failed_attempts;

      if (failedAttempts >= MAX_FAILED_ATTEMPTS) {
        navigate(FORGOT_PASSWORD.INDEX);
      }

      if (message) {
        setFlag(true);

        let messages = [];

        for (let i = 0; i < message.length; i++) {
          messages.push(message[i]);
        }

        setError(
          typeof message === "string"
            ? t("auth.error.emailOrPasswordInvalid")
            : `${messages.join(" ")}`
        );
      }
    }
  };

  return (
    <div className="sign-in position-relative">
      <div className="top-circle position-absolute top-0 start-0">
        <Icon name="TopCircle" />
      </div>
      <div className="bottom-circle position-absolute bottom-0 end-0">
        <Icon name="BottomCircle" />
      </div>
      <div className="container-fluid h-100">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-md-11 col-lg-9">
            <div
              className={`login-form row justify-content-center align-items-center m-0 position-relative ${
                isNewSignInPage && "new-login-form"
              }`}
            >
              <div className="bg-image left col-md-6 col-sm-12 p-0 position-relative">
                <figure className="m-0 bg">
                  <Icon name="bg_icon" />
                </figure>
                <div className="logo">
                  <div className="text-center mb-0 create-account position-absolute w-100">
                    <figure className="text-center">
                      <h1>
                        <Icon name="LogoIcon" />
                      </h1>
                    </figure>
                    {!isNewSignInPage && (
                      <Link
                        to={SIGN_UP.INDEX}
                        className="btn-link ms-1 px-5 btn-lg rounded-2"
                        data-testid="create_new_account_button"
                      >
                        {t("auth.signUp.createAccount")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              <div className="p-4 right col-md-6 col-sm-12">
                <SignInForm
                  userEmail={userEmail}
                  onSubmit={handleSubmit}
                  error={error}
                  flag={flag}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
