import React from "react";

import ButtonLoading from "components/InviteCandidates/ButtonLoading";

const LoadingState = ({ isLoading, loadingLabel, defaultLabel }) => {
  if (isLoading) return <ButtonLoading btnLabel={loadingLabel} />;

  return defaultLabel;
};

export default LoadingState;
