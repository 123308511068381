export const CONSTANTS = {
  BEARER: "Bearer",
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  TOKEN: "token",
  ACTIVE_CANDIDATE_ID: "ACTIVE_CANDIDATE_ID",
  PASSWORD: "password",
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  EXPIRES_IN: "expires_in",
  TOKEN_TYPE: "token_type",
  LOGGED_IN: "logged_in",
  SUPER_ADMIN: "super_admin",
  ROLES: "roles",
  SCOPE: process.env.REACT_APP_CUSTOMER_SCOPE,
};

export const BASE_SLUG_KEY = "onboarding_";

const getKey = (key) => BASE_SLUG_KEY + key;

class LocalStorageManager {
  static storage = window.localStorage;

  static get(key) {
    const data = this.storage.getItem(getKey(key));

    if (data) return JSON.parse(data);

    return data;
  }

  static set(key, value) {
    this.storage.setItem(getKey(key), JSON.stringify(value));
  }

  static remove(key) {
    this.storage.removeItem(getKey(key));
  }

  static clear() {
    this.storage.clear();
  }
}

export default LocalStorageManager;
