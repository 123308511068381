import { OTP_INPUT_COUNT } from "constants/authentication";
import React from "react";
import OtpInput from "react-otp-input";

const OtpInputs = ({ otp, setOtp }) => {
  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <OtpInput value={otp} onChange={handleChange} numInputs={OTP_INPUT_COUNT} />
  );
};

export default OtpInputs;
