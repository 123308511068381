import { useTranslation } from "react-i18next";

import Modal from "components/Shared/Modal/Modal";

import { Button } from "@intelliante/intelliante-ui";

const OtpModal = ({ isVisible, onLaunchSetup }) => {
  const { t } = useTranslation();

  const handleModal = () => {
    onLaunchSetup();
  };

  return (
    <Modal
      isVisible={isVisible}
      className="custom-modal py-4 py-md-4 px-4 px-xxl-4 z-2 otp-modal d-flex justify-content-center flex-column align-items-center"
    >
      <div className="d-flex justify-content-center flex-column align-items-center p-2">
        <h5>{t("message.auth.emailVerified")}</h5>
        <p>{t("modal.otpSuccess.createDashboard")}</p>
        <Button
          buttonText={t("label.launchSetup")}
          onClick={handleModal}
          textClassName="col-lg-4 col-md-12 mx-lg-0 mt-3 btn shadow-none text-uppercase d-flex justify-content-center flex-column align-items-center"
        />
      </div>
    </Modal>
  );
};

export default OtpModal;
