import React from "react";
import { useTranslation } from "react-i18next";
import zxcvbn from "zxcvbn";

import { PASSWORD_STRENGTH } from "constants/formConstants";

const PasswordStrengthMeter = ({ password, checkAll }) => {
  const { t } = useTranslation();
  const testResult = zxcvbn(password);

  const getPasswordStrength = () => {
    if (checkAll) {
      return PASSWORD_STRENGTH.STRONG;
    } else {
      return PASSWORD_STRENGTH.GOOD;
    }
  };

  const getBarWidth = () => {
    if (checkAll) {
      return 100;
    } else {
      return 60;
    }
  };

  const getBarColor = () => {
    if (checkAll) {
      return "#1DB27E";
    } else return "#F8DC5E";
  };

  const barWidth = () => {
    switch (testResult.score) {
      case 0:
        return 20;
      case 1:
        return 60;
      case 2:
        return getBarWidth();
      case 3:
        return getBarWidth();
      case 4:
        return getBarWidth();
      default:
        return 20;
    }
  };

  const createPassLabel = () => {
    switch (testResult.score) {
      case 0:
        return PASSWORD_STRENGTH.WEAK;
      case 1:
        return PASSWORD_STRENGTH.GOOD;
      case 2:
        return getPasswordStrength();
      case 3:
        return getPasswordStrength();
      case 4:
        return getPasswordStrength();
      default:
        return PASSWORD_STRENGTH.WEAK;
    }
  };

  const funcProgressColor = () => {
    switch (testResult.score) {
      case 0:
        return "#EC7474";
      case 1:
        return "#F8DC5E";
      case 2:
        return getBarColor();
      case 3:
        return getBarColor();
      case 4:
        return getBarColor();
      default:
        return "#EC7474";
    }
  };

  const changePasswordColor = () => ({
    width: `${barWidth()}%`,
    background: funcProgressColor(),
  });

  return (
    <>
      <p className="text-white password-strength">
        {t("attributes.passwordStrength")}:{" "}
        <span style={{ color: funcProgressColor() }}>{createPassLabel()}</span>
      </p>
      <div className="progress">
        <div className="progress-bar" style={changePasswordColor()}></div>
      </div>
    </>
  );
};

export default PasswordStrengthMeter;
