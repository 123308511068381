import AuthenticatedApi from "api/AuthenticatedApi";
import BaseRequest from "api/BaseApi";

import { CLIENT_INFO } from "constants/authentication";

const GET_ACCESS_TOKEN = "/accesses";
const REGENERATE_OTP = "/regenerate_otp";
const VERIFY_OTP = "/verify";

class OtpVerifyApi {
  static post(userData) {
    return BaseRequest.post(REGENERATE_OTP, userData);
  }

  static verifyOtpCode(userData) {
    return BaseRequest.post(VERIFY_OTP, userData);
  }

  static getAccessToken() {
    const clientId = {
      client_id: CLIENT_INFO.customer_client_id,
    };

    return AuthenticatedApi.post(GET_ACCESS_TOKEN, clientId);
  }
}

export default OtpVerifyApi;
