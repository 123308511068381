import BgIcon from "assets/images/signUpBg.svg";
import Bg from "assets/images/sign-in.svg";
import TopCircleIcon from "assets/images/top-circle.svg";
import BottomCircleIcon from "assets/images/bottom-circle.svg";
import EyeOn from "assets/images/eye-on.svg";
import Eye from "assets/images/eye.svg";
import LoaderAnimation from "assets/images/LoaderAnimation.png";
import Invalid from "assets/images/invalid.svg";
import Logo from "assets/images/logo1.png";
import LogoIcon from "assets/images/logo-white.svg";
import Mail from "assets/images/mail.svg";
import OTPIcon from "assets/images/otp-lock.svg";
import questionMark from "assets/images/questionMark.svg";
import InfoIcon from "assets/images/info.svg";
import Valid from "assets/images/valid.svg";
import xCircleCloseIcon from "assets/images/xCircle-icon.svg";

const ICONS = {
  Bg: BgIcon,
  bg_icon: Bg,
  valid: Valid,
  invalid: Invalid,
  xclose: xCircleCloseIcon,
  Eye: Eye,
  EyeOn: EyeOn,
  TopCircle: TopCircleIcon,
  BottomCircle: BottomCircleIcon,
  Logo: Logo,
  LogoIcon: LogoIcon,
  LoaderAnimation: LoaderAnimation,
  mail: Mail,
  OTP: OTPIcon,
  questionMark: questionMark,
  info: InfoIcon,
};

export default ICONS;
