import React from "react";

import ICONS from "constants/icons";

const Icon = ({ name, onClick, className }) => {
  let pointerClass = onClick ? "cursor-pointer" : "";

  return (
    <img
      src={ICONS[name]}
      className={`${className} ${pointerClass}`}
      onClick={onClick}
    />
  );
};

export const IntIcon = ({ name, ...props }) => {
  const IconComponent = ICONS[name];

  return <IconComponent {...props} />;
};

export default Icon;
