export const MAX_FAILED_ATTEMPTS = 30;
export const MAX_DESCRIPTION_COUNT = 240;
export const OTP_INPUT_COUNT = 6;
export const NEED_DOMINANT_BASELINE_FIX = 100;
export const MAXIMUM_NUMBER_OF_CHARACTERS_IN_COMPANY_SLUG = 25;

export const RESPONSE_MESSAGES = {
  PASSWORD_SUCCESSFULLY_CHANGED: "Password is successfully updated",
  CONFIRM_ACCOUNT_BEFORE_SIGN_IN:
    "you need to confirm your account before sign in",
  EMAIL_ALREADY_TAKEN: "has already been taken for this company",
  EMAIL_IS_INVALID: "is invalid",
  EMAIL_IS_EMPTY: "can't be blank",
  OTP_CODE_VERIFIED: "OTP code verified",
  OTP_LIMIT_EXCEEDED: "Your attempt has exceeded the maximum limits",
  OTP_CODE_EXPIRED: "OTP code expired",
};

export const CLIENT_INFO = {
  grant_type: "password",
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  scope: process.env.REACT_APP_CUSTOMER_SCOPE,
  customer_client_id: process.env.REACT_APP_CUSTOMER_CLIENT_ID,
};
