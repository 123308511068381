import React from "react";

const CheckboxField = ({
  containerClass,
  className,
  labelClassName,
  label,
  id,
  onChange,
  value,
  disabled,
  checked,
}) => {
  const defaultClassName = ["form-check-input"];
  const defaultContainerClass = ["form-check"];
  const defaultLabelClassName = ["form-check-label"];

  if (className) defaultClassName.push(className);
  if (defaultContainerClass) defaultContainerClass.push(containerClass);
  if (labelClassName) defaultLabelClassName.push(labelClassName);

  return (
    <div className={defaultContainerClass.join(" ")}>
      <input
        onChange={onChange}
        type="checkbox"
        id={id}
        className={defaultClassName.join(" ")}
        value={value}
        disabled={disabled}
        checked={checked}
      />
      <label className={defaultLabelClassName.join(" ")} for={id}>
        {label}
      </label>
    </div>
  );
};

export default CheckboxField;
