import BaseRequest, { DEFAULT_HEADERS } from "./BaseApi";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

const getToken = () =>
  `${LocalStorageManager.get(CONSTANTS.TOKEN_TYPE)} ${LocalStorageManager.get(
    CONSTANTS.ACCESS_TOKEN
  )}`;

class AuthenticatedApi extends BaseRequest {
  static authenticatedHeaders() {
    const authHeader = { Authorization: getToken() };
    const headers = { ...DEFAULT_HEADERS, ...authHeader };

    return headers;
  }

  static headers() {
    const defaultHeaders = { headers: this.authenticatedHeaders() };

    return { ...defaultHeaders };
  }
}

export default AuthenticatedApi;
