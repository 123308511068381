import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { TextArea, Input } from "@intelliante/intelliante-ui";

import FormFieldLabelRender from "components/Onboarding/CompanyInfo/CompanyInformationForm/FromFieldLabelRender";

import { ERROR_TYPES } from "constants/formConstants";

import { FormWarning } from "./FormWarning";

const ErrorText = ({ isValidInput, error }) => {
  if (!isValidInput) return null;

  return <span className="error-message">{error}</span>;
};

const FormField = ({
  name,
  containerClassName,
  inputClassName,
  labelClassName,
  placeholder,
  validate,
  label,
  textArea,
  rows,
  cols,
  disabled,
  submitError,
  customError,
  onChangeCallBack,
  type,
  value,
  activeLabel,
  iconName,
  onFocus,
  onKeyUp,
  handleShowPassword,
  warningText,
}) => {
  const { t } = useTranslation();

  return (
    <Field
      FieldValue={value}
      type={type}
      submitError={submitError}
      name={name}
      containerClassName={containerClassName}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      activeLabel={activeLabel}
      placeholderText={t(placeholder)}
      validate={validate}
      fieldLabel={t(label)}
      fieldDisabled={disabled}
      customError={customError}
      iconName={iconName}
      onFocus={onFocus}
      handleShowPassword={handleShowPassword}
      warningText={warningText}
      format={(value = "") => {
        if (typeof value !== ERROR_TYPES.STRING) return value;

        return value.trim();
      }}
      formatOnBlur
      render={({
        input: { onChange, ...input },
        containerClassName,
        placeholderText,
        meta,
        fieldLabel,
        activeLabel,
        iconName,
        handleShowPassword,
        onFocus,
        warningText,
        FieldValue,
      }) => {
        const [inputVariant, setInputVariant] = useState("");
        const isInputField = !textArea;
        const { error, touched, modified } = meta;
        const errorFieldVariant = "error";

        const activeLabelText = activeLabel ? "active" : "";

        const handleOnChange = (e) => {
          onChange(e);
          onChangeCallBack(e);
        };

        const isValidInput = error && (touched || modified);

        useEffect(() => {
          if (isValidInput) setInputVariant(errorFieldVariant);
          if (!error) setInputVariant("");
        }, [error, touched, modified]);

        const inputFieldType = input?.type ? input.type : "text";

        return (
          <div className={containerClassName}>
            <FormFieldLabelRender isInputField={!isInputField}>
              <TextArea
                id={input.name}
                inputType="textArea"
                name={input.name}
                labelText={fieldLabel}
                placeholderText={placeholderText}
                value={input.FieldValue}
                rowsNumber={rows}
                colsNumber={cols}
                size="md"
                variant={inputVariant}
                onChange={handleOnChange}
                smallText={
                  <ErrorText isValidInput={isValidInput} error={error} />
                }
                activeLabel={activeLabelText}
              />
            </FormFieldLabelRender>

            <FormFieldLabelRender isInputField={isInputField}>
              <Input
                id={input.name}
                inputType={inputFieldType}
                name={input.name}
                labelText={fieldLabel}
                placeholderText={placeholderText}
                value={FieldValue}
                size="md"
                variant={inputVariant}
                onChange={handleOnChange}
                smallText={
                  <ErrorText isValidInput={isValidInput} error={error} />
                }
                activeLabel={activeLabel ? "active" : ""}
                iconName={iconName}
                onFocus={onFocus}
                onIconClick={handleShowPassword}
                onKeyUp={onKeyUp}
              />
            </FormFieldLabelRender>
            <FormWarning warningText={warningText} />
          </div>
        );
      }}
    />
  );
};

FormField.defaultProps = {
  containerClassName: "",
  inputClassName: "",
  labelClassName: "",
  placeholder: "",
  disabled: false,
  textArea: false,
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  textArea: PropTypes.bool,
};

export default FormField;
