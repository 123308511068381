import { t } from "i18next";

import {
  MAXIMUM_NUMBER_OF_CHARACTERS_IN_COMPANY_SLUG,
  MAX_DESCRIPTION_COUNT,
} from "constants/authentication";

import { REG_EXP } from "constants/index";

export const isInputValid = (value) => value.trim().length;

export const isLengthValid = (value) =>
  value
    ? value.toString().length < MAX_DESCRIPTION_COUNT
      ? undefined
      : t("formValues.descriptionError")
    : "";

export const required = (value) => (value ? undefined : "Please enter ");

export const requiredCompanyName = (value) =>
  value ? undefined : t("formValues.enterCompanyName");

export const requiredCompanySlug = (value) =>
  value ? undefined : t("formValues.enterCompanySlug");

export const isValidSlug = (value) =>
  value && REG_EXP.SLUG.test(value) ? undefined : t("formValues.invalidSlug");

export const maxLength = (value) =>
  value.toString().length < MAXIMUM_NUMBER_OF_CHARACTERS_IN_COMPANY_SLUG
    ? undefined
    : t("formValues.slugExceedsAllowedCharacters");

export const isPasswordInputValid = (value) =>
  value?.trim().length ? undefined : t("auth.error.passwordEmptyMessage");

export const isConfirmPasswordInputValid = (value) =>
  value?.trim().length
    ? undefined
    : t("auth.error.confirmPasswordEmptyMessage");
