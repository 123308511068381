import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { SIGN_UP } from "constants/routes.js";

import LocalStorageManager from "utils/localStorageManager";

const ProtectedRoute = () => {
  const location = useLocation();

  if (LocalStorageManager.get("access_token")) return <Outlet />;

  return <Navigate to={SIGN_UP.INDEX} state={{ path: location.pathname }} />;
};

export default ProtectedRoute;
