import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import SignUpApi from "api/AuthApi/SignUpApi";

import Icon from "components/Shared/Icon";
import SignUpForm from "components/SignUp/SignUpForm";

import { RESPONSE_MESSAGES } from "constants/authentication";
import { OTP_VERIFY, SIGN_IN } from "constants/routes";

import { CONSTANTS } from "utils/localStorageManager";

import Modal from "components/Shared/Modal/Modal";
import { PrivacyPolicy, TermsOfUse } from "./signupContent";

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [signUpError, setSignUpError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [policyError, setPolicyError] = useState("");
  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (email, password, confirmPassword) => {
    setIsLoading(true);

    try {
      const body = {
        user: {
          email: email,
          password: password,
          password_confirmation: confirmPassword,
          client_id: CONSTANTS.CLIENT_ID,
          client_secret: CONSTANTS.CLIENT_SECRET,
        },
      };

      const response = await SignUpApi.post(body);

      setIsLoading(false);

      navigate(
        `${OTP_VERIFY.INDEX}?email=${encodeURIComponent(
          email
        )}&otp_token=${encodeURIComponent(response?.data?.otp_token)}`,
        {
          state: {
            email: email,
            password: password,
          },
        }
      );
    } catch (error) {
      setIsLoading(false);

      if (
        error.response.data.email?.[0] === RESPONSE_MESSAGES.EMAIL_ALREADY_TAKEN
      )
        setSignUpError(t("auth.error.emailAlreadyTaken"));
      else if (
        error.response.data.email?.[0] === RESPONSE_MESSAGES.EMAIL_IS_INVALID
      )
        setSignUpError(t("auth.error.invalidEmail"));
      else if (
        error.response.data.email?.[0] === RESPONSE_MESSAGES.EMAIL_IS_EMPTY
      )
        setSignUpError(t("auth.error.emailError"));
    }
  };

  return (
    <div className="sign-in sign-up">
      <div className="top-circle  position-absolute top-0 start-0">
        <Icon name="TopCircle" alt="Top Circle" className="img-fluid" />
      </div>
      <div className="bottom-circle position-absolute bottom-0 end-0">
        <Icon name="BottomCircle" alt="Bottom Circle" className="img-fluid" />
      </div>
      <div className="container-fluid h-100">
        <div className="row d-flex justify-content-center align-items-center min-vh-100">
          <div className="col-md-11 col-lg-9">
            <div className="login-form row m-0 position-relative">
              <div className=" border-0 p-4 right col-md-6 col-sm-12">
                <div className="d-flex flex-column justify-content-center h-100">
                  <SignUpForm
                    error={signUpError}
                    setError={setSignUpError}
                    onSubmit={handleSubmit}
                    isLoading={isLoading}
                    setIsModalOpen={setIsModalOpen}
                    setModalText={setModalText}
                    isAgree={isAgree}
                    setIsAgree={setIsAgree}
                    policyError={policyError}
                    setPolicyError={setPolicyError}
                    setIsPrivacyPolicy={setIsPrivacyPolicy}
                  />
                  {/* Modal of Terms Of services and Privacy Policy */}
                  <Modal
                    isVisible={isModalOpen}
                    className="policy-modal d-flex flex-column align-items-center py-4 py-md-4 px-4"
                    handleClose={handleClose}
                    isInformativeModal={true}
                  >
                    <div className="d-flex justify-content-center flex-column width-100">
                      <div className="d-flex justify-content-between text-white">
                        <p className="d-flex align-items-center modal-text">
                          {modalText}
                        </p>
                        <Icon
                          name="xclose"
                          className="x-circle"
                          onClick={handleClose}
                        />
                      </div>
                      <hr className="text-white" />
                      {isPrivacyPolicy ? <PrivacyPolicy /> : <TermsOfUse />}
                    </div>
                  </Modal>
                </div>
              </div>

              <div className="bg-image left col-md-6 col-sm-12 p-0 position-relative">
                <figure className="m-0 bg h-100">
                  <Icon name="Bg" alt="background" />
                </figure>
                <div className="logo">
                  <div className="text-center mb-0 create-account position-absolute w-100">
                    <figure className="text-center">
                      <h1>
                        <Icon name="LogoIcon" alt="Logo Intelliante" />
                      </h1>
                    </figure>
                    <Link
                      to={SIGN_IN.INDEX}
                      className="btn-link ms-1 px-5 btn-lg rounded-2"
                      data-testid="sign_in_now_button"
                    >
                      {t("auth.signUp.signInNow")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
